import {
    Filter,
    SelectInput,
    ReferenceInput
} from 'react-admin';
import {Role} from "../Constant/Role";

// Filter component
export const ListFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            source="role"
            choices={Role}
            alwaysOn
        />
    </Filter>
);

