import { apiRoutes } from "utils/constants";
import { api } from "./api";

export const fileService = {
  async uploadFile(body) {
    const formData = new FormData();

    formData.append("file", body.file);
    formData.append("folder", body.folder);
    formData.append("type", body.type);

    const response = await api.post(apiRoutes.file, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  },
  async deleteFile(fileId) {
    const response = await api.delete(`${apiRoutes.file}/${fileId}`);
    return response.data;
  },

  async uploadPhoto(adminId, photoId) {
    const response = await api.post(`/admins/${adminId}/edit/photo`, {
      photo: photoId,
    });
    return response.data;
  },

  async deletePhoto(adminId) {
    const response = await api.delete(`/admins/${adminId}/photo`);
    return response.data;
  },
};
