import * as React from "react";
import { AppBar } from 'react-admin';
import inMemoryJwt from "Application/Auth/inMemoryJwt";
import Logo from "./Logo";

const MyAppBar = props => {
    const adminName = inMemoryJwt.getName();
    const adminEmail = inMemoryJwt.getEmail();

    const classes = {};
    return (
        <AppBar {...props}>
            <span style={{flex: 2}} />
            <Logo />
            <span style={{flex: 2}} />
        </AppBar>
    );
};

export default MyAppBar;