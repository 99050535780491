import React, { useRef, useState } from "react";
import { required, minLength, Button } from "react-admin";
import {
  TextInputMod,
  PasswordInputMod,
  SelectInputMod,
  ImageInputMod,
} from "Component/Layouts/Input/index";
import {
  CreateMod,
  SimpleFormMod,
  ToolBarButtonModCreate,
  ActionsMod,
} from "Component/Layouts/Form/index";
import {
  EmailValidation,
  RepeatPasswordValidation,
} from "Component/Validation/index";
import { Role } from "Pages/Admin/Constant/Role";
import { Category } from "../Constant/Category";
import { Type } from "../Constant/Type";
import { useTranslate } from "react-admin";
import { fileService } from "api/file-service";
import { makePhotoSource } from "utils/constants";

export const AdminsCreate = (props) => {
  const translate = useTranslate();
  const [selectedRole, setSelectedRole] = useState(null);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
  const [photo, setPhoto] = useState(null);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const hiddenFileInput = useRef(null);

  const handleChangePhoto = async (event) => {
    try {
      if (event.target.files) {
        setIsLoadingPhoto(true);
        const response = await fileService.uploadFile({
          file: event.target.files[0],
          folder: "admins",
          type: "photo",
        });
        setPhoto(response);
        setIsLoadingPhoto(false);
      }
    } catch (error) {
      if (error) {
        // toast({
        //   duration: 3000,
        //   description: error.response?.data.message,
        //   toastVariant: 'error',
        // })
        console.log(error);
        setIsLoadingPhoto(false);
      }
    }

    event.target.value = "";
  };

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleDeletePhoto = () => {
    setPhoto(null);
  };

  return (
    <CreateMod
      actions={<ActionsMod title={translate("ra.action.create") + " Admin"} />}
      {...props}
    >
      <SimpleFormMod
        toolbar={
          <ToolBarButtonModCreate showCancel="true" hrefCancel="/#/admins" />
        }
        encType="multipart/form-data"
      >
        <TextInputMod
          fullWidth
          label="First Name"
          source="firstName"
          validate={required()}
        />
        <TextInputMod
          fullWidth
          label="Last Name"
          source="lastName"
          validate={required()}
        />
        <SelectInputMod
          fullWidth
          label="Role"
          source="role"
          id="adminRole"
          validate={required()}
          choices={Role}
          onChange={handleRoleChange}
        />
        {selectedRole === "ROLE_DOCTOR" && (
          <SelectInputMod
            fullWidth
            label="Type"
            source="type"
            id="type"
            choices={Type}
            validate={required()}
          />
        )}
        {selectedRole === "ROLE_DOCTOR" && (
          <SelectInputMod
            fullWidth
            label="Category"
            source="category"
            id="category"
            choices={Category}
            validate={required()}
          />
        )}
        {selectedRole === "ROLE_DOCTOR" && (
          <TextInputMod
            fullWidth
            label="Phone"
            source="phone"
            id="phone"
            validate={required()}
          />
        )}
        <TextInputMod
          fullWidth
          label="Email"
          source="email"
          validate={[required(), EmailValidation]}
        />
        <TextInputMod
          fullWidth
          label="Description"
          source="description"
          validate={[]}
        />
        <PasswordInputMod
          fullWidth
          label="ra.auth.password"
          source="password"
          validate={[required(), minLength(8)]}
        />
        <PasswordInputMod
          fullWidth
          label="Password Repeat"
          source="passwordRepeat"
          validate={[required(), RepeatPasswordValidation, minLength(8)]}
        />
        {/* <div className="flex gap-4 mb-4">
          {isLoadingPhoto ? (
            <div className="bg-background-tertiary rounded-[5px]  size-[52px]  flex justify-center items-center text-xl font-semibold sm:ml-0 sm:size-[80px] lg:size-[100px] sm:text-[32px]">
              Loading...
            </div>
          ) : photo ? (
            // <Avatar
            //   variant="square"
            //   className="size-[52px] sm:ml-0 sm:size-[80px] lg:size-[100px]"
            //   photoSrc={makePhotoSource(photo.source)}
            //   alt={'Pet Photo'}
            // />
            <img
              src={makePhotoSource(photo.source)}
              alt="avatar"
              style={{ width: "100px", height: "100px" }}
            />
          ) : (
            <div className="bg-background-tertiary rounded-[5px]  size-[52px]  flex justify-center items-center text-xl font-semibold sm:ml-0 sm:size-[80px] lg:size-[100px] sm:text-[32px]">
              Add image
            </div>
          )}

          {photo && (
            <>
              <Button
                onClick={handleClick}
                variant="text"
                size="icon"
                className="text-black-primary"
              >
                Edit
              </Button>
              <Button
                className="text-error"
                onClick={handleDeletePhoto}
                variant="text"
                size="icon"
              >
                Remove
              </Button>
            </>
          )}
          {!photo && (
            <Button
              variant="text"
              size="icon"
              className="text-black-primary"
              onClick={handleClick}
            >
              Upload
            </Button>
          )}
          <input
            accept=".jpeg, .jpg, .png, .webp"
            type="file"
            style={{ display: "none" }}
            onChange={(event) => handleChangePhoto(event)}
            ref={hiddenFileInput}
          />
          
        </div> */}
        <ImageInputMod
          label="Photo"
          source="photo"
          // tyle={{ display: "none" }}
          onChange={(event) => handleChangePhoto(event)}
          ref={hiddenFileInput}
        />
      </SimpleFormMod>
    </CreateMod>
  );
};
