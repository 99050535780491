import { ImageInput, ImageField } from "react-admin";

const ImageInputMod = (props) => {
  return (
    <ImageInput {...props} accept="image/*">
      <ImageField source="src" title="title" />
    </ImageInput>
  );
};

export default ImageInputMod;
