import { useRecordContext} from 'react-admin';
import React from "react";

const PhoneTypeField = props => {
    const record = useRecordContext();

    return <span>{record ? `${props.list.find(el => el.id === record.type).name}` : ''}</span>;
};

export default PhoneTypeField;
