import {useRefresh, ReferenceInput, SelectInput, usePermissions, Menu} from 'react-admin';
import { useLocation } from 'react-router-dom';
import React, {useState} from 'react';
import SubMenu from "./SubMenu";
import DomainIcon from '@mui/icons-material/Domain';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BuildIcon from '@mui/icons-material/Build';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { useTranslate } from 'react-admin';

/*const useStyles = makeStyles(
    theme => ({
        icon: {minWidth: theme.spacing(5)},
    }),
    {name: 'RaMenuItemLink'}
);*/

const CustomMenu = (props) => {
    const {permissions} = usePermissions();
    const { pathname } = useLocation();
    const translate = useTranslate();
    const refresh = useRefresh();

    return (
        <Menu {...props}>
            <SubMenu primaryText={translate('menu.users.name')} leftIcon={<EventNoteIcon/>} isDropdownOpen={['/users'].includes(pathname)}>
                <Menu.Item to="/users" primaryText={translate('menu.users.name')} leftIcon={<FormatListBulletedIcon/>}/>
            </SubMenu>
            <Menu.Item to="/admins" primaryText={translate('menu.admins.name')} leftIcon={<AccountBoxIcon />}/>
            <SubMenu primaryText={translate('menu.settings.name')} leftIcon={<SettingsIcon/>} isDropdownOpen={['/settings'].includes(pathname)}>
                <Menu.Item
                    to="/doctor/on-duty"
                    primaryText={translate('menu.doctorsOnDuty.name')}
                    leftIcon={<LocalHospitalIcon />}
                />
            </SubMenu>
        </Menu>
    );
}

export default CustomMenu;
