import { FunctionField } from "react-admin";
import { PetType } from "../../../Pages/User/Constant/PetType";

const PetsField = () => (
  <FunctionField
    render={(record) =>
      record.pets
        ? record.pets
            .map(
              (pet) =>
                `${pet?.name} (${
                  PetType.find((el) => el.id === pet?.petType)?.name
                })`
            )
            .join(", ")
        : ""
    }
  />
);

export default PetsField;
