import React from 'react';
import {ShowMod, TabbedShowLayoutMod, TabMod} from "Component/Redesign/Show";
import ClientsTab from "./Tabs/clientsTab";
import ActivityTab from "./Tabs/activityTab";
import ScheduleTab from "./Tabs/schedule/scheduleTab";
import AppointmentsTab from "./Tabs/appintmantsTab";
import InformationTab from "./Tabs/informationTab";

const PostShowActions = () => (
    <div></div>
);
export const AdminsShow = ({permissions, ...props}) => (

    <ShowMod {...props} actions={<PostShowActions />}>
        <TabbedShowLayoutMod >
            <TabMod label="resources.admins.tabs.information">
                <InformationTab/>
            </TabMod>
            <TabMod label="resources.admins.tabs.clients" path="clients">
                <ClientsTab/>
            </TabMod>
            <TabMod label="resources.admins.tabs.appointments" path="appointment">
                <AppointmentsTab/>
            </TabMod>
            <TabMod label="resources.admins.tabs.schedule" path="schedule">
                <ScheduleTab/>
            </TabMod>
            <TabMod label="resources.admins.tabs.income" path="income">
                {/*<IncomeTab/>*/}
            </TabMod>
            <TabMod label="resources.admins.tabs.activity" path="activity">
                <ActivityTab/>
            </TabMod>
        </TabbedShowLayoutMod>
    </ShowMod>
);
