import * as React from "react";
import {Button, DeleteButton, SaveButton, Toolbar} from 'react-admin';

const ToolBarButtonModCreate = props => {
    let showCancel = props.showCancel || false;
    let hrefCancel = props.hrefCancel || '';
    let textSave = props.textSave || 'Save';

    return (<Toolbar {...props} style={{
        display: 'flex',
        justifyContent: 'space-between',
        '& svg': {
            display: 'none'
        }
    }}>
        {showCancel ?
            (<Button label='ra.action.cancel' href={props.hrefCancel}  undoable={false} style={{
                border: '1px solid #4884EF',
                borderRadius: '4px',
                padding: '6px 16px',
                marginLeft: 'auto',
                marginRight: '20px'
            }} />) : <div></div>
        }
        <SaveButton style={{
            padding: '6px 30px',
            marginLeft: 0,
            borderRadius: '4px'
        }} label={props.textSave} />
    </Toolbar>);
};

export default ToolBarButtonModCreate;
