import React from "react";
import {
  ArrayField,
  Datagrid,
  TextField,
  BooleanField,
  SelectField,
  useRecordContext,
} from "react-admin";
import { Status } from "../Constant/Status";
import {
  ShowMod,
  TabbedShowLayoutMod,
  TabMod,
} from "Component/Redesign/Show/index";
import {
  TextFieldMod,
  EmailFieldMod,
  StatusField,
  ImageFieldMod,
  DateFieldMod,
} from "Component/Layouts/Field/index";
import { PetType } from "../Constant/PetType";
import { NoResults } from "Component/NoResults/NoResults";
import { makePhotoSource } from "utils/constants";
import { PhotoField } from "../PhotoField";

const PostShowActions = () => <div></div>;

export const UserShow = ({ permissions, ...props }) => {
  const record = useRecordContext();

  console.log(record);

  return (
    <ShowMod {...props} actions={<PostShowActions />}>
      <TabbedShowLayoutMod>
        <TabMod label="resources.admins.tabs.information">
          <TextFieldMod source="id" sortBy="id" />
          <TextFieldMod source="name" />
          <EmailFieldMod source="email" />
          <StatusField
            source="status"
            statusList={Status}
            style={{ width: "10%" }}
          />
          <BooleanField source="isBlackList" />
          <DateFieldMod source="createdAt" showTime />
          <TextFieldMod
            label="resources.users.fields.subscription"
            source="subscription.expiredAt"
          />
          <ArrayField source="pets">
            <Datagrid bulkActionButtons={false} empty={<NoResults />}>
              <TextField label="resources.pets.fields.id" source="id" />
              <TextField label="resources.pets.fields.name" source="name" />
              <SelectField
                label="resources.pets.fields.petType"
                source="petType"
                choices={PetType}
              />
              <TextField label="resources.pets.fields.breed" source="breed" />
              <TextField label="resources.pets.fields.gender" source="gender" />
              <TextField label="resources.pets.fields.weight" source="weight" />
              <DateFieldMod
                label="resources.pets.fields.birtDate"
                source="birtDate"
              />
              <PhotoField />
            </Datagrid>
          </ArrayField>
        </TabMod>
      </TabbedShowLayoutMod>
    </ShowMod>
  );
};
