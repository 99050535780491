import React from "react";
import {
  Datagrid,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  useRecordContext,
  Button,
} from "react-admin";
import ListMod from "Component/Redesign/ListMod";
import CreateButtonMod from "Component/Layouts/Button/CreateButton";
import CrudMod from "Component/Layouts/Button/CrudMod";
import { LabelValueFromConst } from "Component/Handlers/ValueFromConst";
import { Role } from "../Constant/Role";
import { Status } from "../Constant/Status";
import LockRounded from "@material-ui/icons/LockRounded";
import { Tooltip } from "@material-ui/core";
import StatusField from "Component/Layouts/Field/StatusField";
import { Boolean } from "../Constant/Boolean";
import OnDutyField from "Component/Layouts/Field/OnDutyField";
import { DateFieldMod } from "../../../Component/Layouts/Field";
import { ListFilter } from "../Filter/ListFilter";
import { useTranslate } from "react-admin";
import { NoResults } from "Component/NoResults/NoResults";
import PhotoButton from "Component/Layouts/Button/PhotoButton";

const ListActions = ({ basePath, className, ...rest }) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButtonMod basePath={basePath} label="ra.action.create" />
    </TopToolbar>
  );
};

const ChangePassword = () => {
  const { id } = useRecordContext();
  const translate = useTranslate();

  return (
    <Button href={`#/admins/password/${id}`}>
      <Tooltip title={translate("buttons.changePassword")}>
        <LockRounded />
      </Tooltip>
    </Button>
  );
};

const RoleField = (props) => {
  const record = useRecordContext();
  if (record[props.source] !== undefined) {
    return LabelValueFromConst(
      Role,
      record[props.source].replace(/[^A-Z^-a-z]/g, "", "")
    );
  }

  return LabelValueFromConst(Role, record[props.source]);
};

export const AdminsList = ({ permissions, ...props }) => {
  return (
    <ListMod
      {...props}
      actions={<ListActions />}
      title="Admin management"
      basePath="/admins"
      filters={<ListFilter />}
    >
      <Datagrid bulkActionButtons={false} empty={<NoResults />}>
        <TextField source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <RoleField source="role" sortable={false} />
        <StatusField source="status" statusList={Status} sortable={true} />
        <OnDutyField source="isOnDuty" list={Boolean} sortable={true} />
        <TextField source="description" sortable={false} />
        <DateFieldMod source="createdAt" showTime />
        <PhotoButton />
        <CrudMod edit="true" del="true" show="true">
          <ChangePassword />
        </CrudMod>
      </Datagrid>
    </ListMod>
  );
};
