import { FunctionField, useRecordContext } from "react-admin";
import {
  DateFieldMod,
  EmailFieldMod,
  ImageFieldMod,
  TextFieldMod,
} from "../../../../../Component/Layouts/Field";
import StatusField from "../../../../../Component/Layouts/Field/StatusField";
import { Status } from "../../../Constant/Status";
import { TabMod } from "../../../../../Component/Redesign/Show";
import OnDutyField from "../../../../../Component/Layouts/Field/OnDutyField";
import { Boolean } from "../../../Constant/Boolean";
import { Category } from "../../../Constant/Category";
import { Type } from "../../../Constant/Type";
import { makePhotoSource } from "utils/constants";

const InformationTab = () => {
  const record = useRecordContext();

  return (
    <TabMod>
      <TextFieldMod source="id" sortBy="id" />
      <TextFieldMod source="firstName" />
      <TextFieldMod source="lastName" />
      <TextFieldMod source="description" />
      <EmailFieldMod source="email" />
      <TextFieldMod source="phone" />
      {record.photo && (
        <>
          <div
            style={{
              fontSize: "12px",
              marginBottom: "3px",
              opacity: "0.6",
              fontFamily: "Roboto",
              color: "black",
            }}
          >
            Photo
          </div>
          <img
            src={makePhotoSource(record.photo.source)}
            alt="avatar"
            style={{ width: "60px", height: "60px" }}
          />
        </>
      )}
      <StatusField
        source="status"
        statusList={Status}
        style={{ width: "5%" }}
      />
      {record.role === "ROLE_DOCTOR" ? (
        <OnDutyField
          label="resources.admins.fields.isOnDuty"
          list={Boolean}
          style={{ width: "5%" }}
        />
      ) : null}
      {record.role === "ROLE_DOCTOR" ? (
        <FunctionField
          label="resources.admins.fields.category"
          source="category"
          render={(record) =>
            `${Category.find((el) => el.id === record.category).name}`
          }
        />
      ) : null}
      {record.role === "ROLE_DOCTOR" ? (
        <FunctionField
          label="Type"
          source="type"
          render={(record) =>
            `${Type.find((el) => el.id === record.type).name}`
          }
        />
      ) : null}
      <DateFieldMod source="createdAt" showTime />
    </TabMod>
  );
};

export default InformationTab;
