import axios from 'axios';
import { REFRESH_TOKEN_KEY } from '../utils/constants';

import authService from './auth-service';

const API_URL = process.env.REACT_APP_DOMAIN;

export const api = axios.create({
  baseURL: `${API_URL}/api/admin`,
headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    'api-version': '1.4',
  },
});


api.interceptors.request.use(
  async (config) => {
    if (authService.isLoggedIn()) {
      config.headers.Authorization = `Bearer ${authService.getAccessKey()}`;
    }

    return config;
  }
);

api.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (error) => {
    console.log(error);
    
    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

      if (refreshToken) {
        try {
          await authService.refreshToken(refreshToken.token);
          document.location.reload();
        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            authService.logout();
            // notification.error({
            //   message: t('common.error'),
            //   description: error.response.data.detail,
            //   duration: 2,
            // });
          }
        }
      } else {
        authService.logout();
      }
    }

    return Promise.reject(error);
  }
);