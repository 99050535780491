import * as React from "react";
import {CreateButton} from 'react-admin';

const CreateButtonMod = props => {
    return (
        <CreateButton style={{
            color: 'white',
            background: '#4884EF',
            padding: '7px 18px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '16px',
            '&:hover': {
                backgroundColor: '#4884EF'
            }
        }}  basePath={props.basePath} label='ra.action.create' />
    );
};

export default CreateButtonMod;
