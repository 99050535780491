import { NoResults } from "Component/NoResults/NoResults";
import { Datagrid } from "react-admin";

const DataGridMod = (props) => {
  return (
    <Datagrid {...props} bulkActionButtons={false} empty={<NoResults />} />
  );
};

export default DataGridMod;
