
const PasswordValidation = (value) => {
    let pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\!\#\$\%\-\_\=\+\<\>\@\&\*]).{8,50}$/;

    if (!pattern.test(value)) {
        return 'Please provide the password with minimum 8 symbols, maximum 50, must contain at least 1 of each: number, symbol, letter';
    }
    return undefined;
};

export default PasswordValidation;
