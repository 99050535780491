import { apiRoutes } from "utils/constants";
import { api } from "./api";

export const chatService = {
    async getUser(id) {
        const response = await api.get(`${apiRoutes.users}/${id}`)
        return response.data;
    },

    async getChatMessages(id) {
        const response = await api.get(`${apiRoutes.chat}/${id}${apiRoutes.message}`)
        return response.data;
    },

    async sendMessage(id, body) {
        const response = await api.post(`${apiRoutes.sendMesage}/${id}${apiRoutes.message}`, body)
        return response.data;
    },
}