import {
    Filter,
    TextInput,
    DateInput,
    SelectInput,
    ReferenceInput,
    SearchInput,
    useRecordContext
} from 'react-admin';
import {Status} from "../Constant/Status";
import {Boolean} from "../Constant/Boolean";

// Filter component
export const ListFilter = (props) => (
    <Filter {...props}>
        {/* Text filter */}
        <TextInput
            source="name"
            alwaysOn
        />
        <TextInput
            source="phone"
            alwaysOn
        />
        <SelectInput
            source="subscription"
            choices={Boolean}
            alwaysOn
        />
        <ReferenceInput
            source="doctor"
            reference="doctors"
            alwaysOn
        >
            <SelectInput
                optionText={<FullNameField />}
                alwaysOn
            />
        </ReferenceInput>

        {/* Basic text search */}
        <SearchInput
            source="q"
            placeholder="Search by title or content..."
            //alwaysOn
        />

        <SelectInput
            label="Status"
            source="status"
            choices={Status}
            //alwaysOn
        />

        {/* Date range filter */}
        <DateInput
            label="Published After"
            source="publishedAt_gte"
            //alwaysOn
        />
        <DateInput
            label="Published Before"
            source="publishedAt_lte"
            //alwaysOn
        />
    </Filter>
)

const FullNameField = () => {
    const record = useRecordContext();
    return <span>{record.firstName} {record.lastName}</span>;
}
