import { useTranslate } from "react-admin";
import "./styles.css";

export const NoResults = (props) => {
  const { className } = props;
  const translate = useTranslate();

  return (
    <div className={`wrapper ${className}`}>
      {translate("dataGrid.noResults")}
    </div>
  );
};
