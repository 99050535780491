import { SimpleForm, TextInput, DateTimeInput, SaveButton, Button, Toolbar } from 'react-admin';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { scheduleService } from 'api/schedule-service';
import { useParams } from "react-router";
import './styles.css'
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { useSnackbar } from 'notistack';

dayjs.locale('en');

const localizer = momentLocalizer(moment);

const ScheduleTab = () => {
    const [eventsData, setEventsData] = useState([]);
    const [contextMenu, setContextMenu] = useState(null);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false); 
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [errors, setErrors] = useState({}); 
    const [newEventData, setNewEventData] = useState({
        title: '',
        description: '',
        start: '',
        end: '',
        id: undefined,
    });
    const { enqueueSnackbar } = useSnackbar();

    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant });
      };

    const params = useParams()

    const validateTime = (start, end) => {
        const now = new Date().getTime();
        const startTime = new Date(start).getTime();
        const endTime = new Date(end).getTime();
        const timeDifference = (endTime - startTime) / (1000 * 60);

        const startDate = new Date(start).toDateString();
        const endDate = new Date(end).toDateString();

        if (startTime < now) {
            return "The start time must be in the future.";
        }

        if (startDate !== endDate) {
            return "The event must start and end on the same day.";
        }

        if (endTime <= startTime) {
            return "End time must be after start time.";
        }

        if (timeDifference < 30) {
            return "The event duration must be at least 30 minutes.";
        }

        if (timeDifference % 15 !== 0) {
            return "The event duration must be a multiple of 15 minutes.";
        }

        return null;
    };

    const handleShowInfoModal = (event) => {
        setNewEventData({
            title: event.title,
            description: event.description,
            start: moment(event.start).format("YYYY-MM-DD HH:mm"),
            end: moment(event.end).format("YYYY-MM-DD HH:mm"),
        });
        setIsInfoModalOpen(true);
    };
    
    const handleEditEvent = (event) => {
        setNewEventData({
            title: event.title,
            description: event.description,
            start: moment(event.start).format("YYYY-MM-DDTHH:mm"),
            end: moment(event.end).format("YYYY-MM-DDTHH:mm"),
            id: event.id,
        });
        
        setContextMenu({ event });
        setIsEditModalOpen(true);
    };

    const closeModal = () => {
        setIsEditModalOpen(false);
        setIsCreateModalOpen(false);
        setIsInfoModalOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewEventData({
            ...newEventData,
            [name]: value
        });
    };

    const checkForConflicts = (start, end, currentEventId = null) => {
        const startTime = new Date(start).getTime();
        const endTime = new Date(end).getTime();
    
        for (const event of eventsData) {
            if (event.id === currentEventId) {
                continue;
            }
    
            const eventStart = new Date(event.start).getTime();
            const eventEnd = new Date(event.end).getTime();
    
            if (
                (startTime >= eventStart && startTime < eventEnd) ||
                (endTime > eventStart && endTime <= eventEnd) ||
                (startTime <= eventStart && endTime >= eventEnd) 
            ) {
                return `The selected time overlaps with another event.`;
            }
        }
        return null;
    };

    const handleUpdateEvents = async (action) => {
        try{
            if(action === 'edit') {
                const timeError = validateTime(newEventData.start, newEventData.end);
                const conflictError = checkForConflicts(newEventData.start, newEventData.end, newEventData.id);
                setErrors({})

                if (timeError) {
                    setErrors({
                        start: timeError,
                        end: timeError,
                    });
                    return; 
                }
                if (conflictError) {
                    setErrors({
                        start: conflictError,
                        end: conflictError,
                    });
                    return; 
                }
                const updatedEvent = {
                    title: newEventData.title,
                    description: newEventData.description,
                    start: new Date(newEventData.start),
                    end: new Date(newEventData.end),
                };
                
                await scheduleService.editWorkingDay(updatedEvent, newEventData.id)
                closeModal()
                
            }
            if (action === 'create') {
                const timeError = validateTime(newEventData.start, newEventData.end);
                const conflictError = checkForConflicts(newEventData.start, newEventData.end);

                setErrors({})
                if (timeError) {
                    handleClickVariant(timeError,'error')
                    closeModal();
                    return;
                }
                if (conflictError) {
                    handleClickVariant(conflictError, 'error');
                    closeModal();
                    return;
                }
            
                const createdEvent = {
                    description: newEventData.description1,
                    title: newEventData.title,
                    start: new Date(newEventData.start),
                    end: new Date(newEventData.end),
                };
            
                await scheduleService.createWorkingDay(createdEvent);
                closeModal();
            }
            if(action === 'delete') {
                console.log(contextMenu.event.id);
                
                await scheduleService.deleteWorkingDay(contextMenu.event.id)
            }
            handleGetWorkingDays()
        } catch (error) {
            console.log(error.message,'error');
            
            handleClickVariant(error.message,'error')
        }
        
    }

    const handleContextMenu = (event, calendarEvent) => {
        event.preventDefault();
        setContextMenu({
            mouseX: event.clientX,
            mouseY: event.clientY,
            event: calendarEvent,
    })
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    const handleSelectSlot = (slotInfo) => {
        setNewEventData((prev) => (
            {
                ...prev,
                start: slotInfo.start,
                end: slotInfo.end,
            }
        ))
        setIsCreateModalOpen(true)
    }

    const handleGetWorkingDays = async () => {
        const response = await scheduleService.getWorkingDays({
            "sort": {
                "field": null,
                "order": null
            },
            "pagination": {
                "perPage": 100,
                "page": 1
            },
            "filter": {
                "doctor": params.id
            }
        })
        
        setEventsData(response.items.map(item => ({
            id: item.id,
            description: item.description,
            title: item.title,
            start: new Date(item.start),
            end: new Date(item.end),
          })));
    }

    useEffect(() => {
        handleGetWorkingDays()
    },[])
    return (
        <div onClick={handleCloseContextMenu}>
            <Calendar
                views={["day", "work_week",]}
                selectable
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="work_week"
                events={eventsData}
                style={{ height: "100vh" }}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleShowInfoModal}
                components={{
                    eventWrapper: ({ children, event }) => (
                        <div
                            onContextMenu={(e) => handleContextMenu(e, event)}
                            style={{ cursor: "context-menu" }}
                        >
                            {children}
                        </div>
                    ),
                }}
            />
            {contextMenu && (
                <div
                    style={{
                        position: "absolute",
                        top: `${contextMenu.mouseY}px`,
                        left: `${contextMenu.mouseX}px`,
                        background: "white",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                        zIndex: 1000,
                    }}
                >
                    <div
                        style={{ padding: "10px", cursor: "pointer" }}
                        onClick={() => handleEditEvent(contextMenu.event)}
                    >
                        Edit
                    </div>
                    <div
                        style={{ padding: "10px", cursor: "pointer", color: "red" }}
                        onClick={() => handleUpdateEvents('delete')}
                    >
                        Delete
                    </div>
                </div>
            )}
            <Dialog open={isInfoModalOpen} onClose={closeModal}>
                <DialogTitle>Event Details</DialogTitle>
                <DialogContent className='createModal'>
                    <div>
                        <p className='formField'><strong>Title:</strong> {newEventData.title}</p>
                        <p className='formField'><strong>Description:</strong> {newEventData.description}</p>
                        <p><strong>Start:</strong> {newEventData.start}</p>
                        <p><strong>End:</strong> {newEventData.end}</p>
                    </div>
                    <Toolbar>
                        <Button label="Close" onClick={closeModal} />
                    </Toolbar>
                </DialogContent>
            </Dialog>
            <Dialog open={isCreateModalOpen} onClose={closeModal}>
                <DialogTitle>Create Event</DialogTitle>
                <DialogContent>
                    <SimpleForm
                    className='createModal'
                        toolbar={
                            <Toolbar>
                                <SaveButton onClick={() => handleUpdateEvents('create')} label="ra.action.create" variant='contained'/>
                                <Button label="ra.action.cancel" onClick={closeModal} />
                            </Toolbar>
                        }
                    >
                        <TextInput
                            source="title"
                            name="title"
                            label="Title"
                            value={newEventData.title}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        <TextInput
                            source="description1"
                            name="description1"
                            label="Description"
                            value={newEventData.description}
                            onChange={handleChange}
                            fullWidth
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={isEditModalOpen} onClose={closeModal}>
                <DialogTitle>Edit Event</DialogTitle>
                <DialogContent>
                    <SimpleForm
                    className='createModal'
                        toolbar={
                            <Toolbar>
                                <SaveButton onClick={() => handleUpdateEvents('edit')} label="Update" />
                                <Button label="ra.action.cancel" onClick={closeModal} />
                            </Toolbar>
                        }
                    >
                        <TextInput
                            source="title"
                            name="title"
                            label="Title"
                            value={newEventData.title}
                            defaultValue={newEventData.title}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        <TextInput
                            source="description"
                            name="description"
                            label="description"
                            value={newEventData.description}
                            defaultValue={newEventData.description}
                            onChange={handleChange}
                            fullWidth
                        />
                        <DateTimeInput
                            source="start"
                            name="start"
                            label="Start Time"
                            value={newEventData.start}
                            defaultValue={newEventData.start}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        <DateTimeInput
                            source="end"
                            name="end"
                            label="End Time"
                            value={newEventData.end}
                            defaultValue={newEventData.end}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        {errors.end && <p style={{ color: "red" }}>{errors.end}</p>}
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ScheduleTab;
