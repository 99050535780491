import {ActionsMod, EditMod, SimpleFormMod, ToolBarButtonModEdit} from "../../../Component/Layouts/Form";
import {SelectInputMod} from "../../../Component/Layouts/Input";
import { required, ReferenceInput, useRecordContext, SelectInput } from 'react-admin';
import {AppointmentStatus} from "../../Admin/Constant/AppointmentStatus";
import React from "react";
import {DateFieldMod} from "../../../Component/Layouts/Field";

export const AppointmentEdit = props => {

    return (
        <EditMod actions={<ActionsMod title={"Edit Appointment"}/>} {...props} mutationMode="pessimistic" redirect="edit">
            <SimpleFormMod toolbar={<ToolBarButtonModEdit showCancel="false" />}
                           variant="outlined" encType="multipart/form-data">
                <FullNameField/>
                <SelectInputMod fullWidth label="Status" source="status" validate={required()} choices={AppointmentStatus}/>
            </SimpleFormMod>
        </EditMod>
    );
};

const FullNameField = () => {
    const record = useRecordContext();
    console.log(record)
    return <ReferenceInput
                label="Period"
                source="period.id"
                reference="period"
                filter={{ doctor: record.doctorId, status: "available"}}
            >
                <SelectInput
                    optionText={<PeriodOptions/>}
                    alwaysOn
                />
             </ReferenceInput>
}
const PeriodOptions = () => {
    return <span><DateFieldMod
        locales="en-GB"
        label="Date"
        source="start"
        options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }}

        />
        <span>      </span>
        <DateFieldMod
            label="Date"
            source="start"
            options={{
                hour: '2-digit',
                minute: '2-digit',
                hourCycle: 'h24'
            }}
            showTime
        />
        <span>  -  </span>
       <DateFieldMod
           label="Date"
           source="end"
           options={{
               hour: '2-digit',
               minute: '2-digit',
               hourCycle: 'h24'
           }}
           showTime
       />
    </span>
;
            }
