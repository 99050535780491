import {
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    useRecordContext,
    useNotify,
    useTranslate,
} from 'react-admin';


export const DoctorsOnDutyCreate = () => {
    const notify = useNotify();
    const translate = useTranslate();
    const onSuccess = (data) => {
        notify(`Changes saved`);
        window.location.reload();
    };

    return (<Create mutationOptions={{ onSuccess }}>
        <SimpleForm>
            <ReferenceInput
                source={translate("resources.doctors.name")}
                reference="admins"
                alwaysOn
                filter={{ isOnDuty: false , status: "active", role: "ROLE_DOCTOR"}}
            >
                <SelectInput
                    optionText={<FullNameField />}
                    alwaysOn
                />
            </ReferenceInput>
        </SimpleForm>
    </Create>);
}

const FullNameField = () => {
    const record = useRecordContext();
    return <span>{record.firstName} {record.lastName}</span>;
}
