
const EmailValidation = (value) => {
    let pattern = /\S+@\S+\.\S+/;

    if (!pattern.test(value)) {
        return 'Email not valid';
    }
    return undefined;
};

export default EmailValidation;