import {fetchUtils} from 'react-admin';
import { stringify } from 'qs';
import inMemoryJWT from '../Auth/inMemoryJwt';

const apiUrl = process.env.REACT_APP_DOMAIN + '/api/admin';

const httpClient = (url, options = {}, blob = false) => {
    if (!options.headers) {
        options.headers = new Headers({});
    }
    if (inMemoryJWT.getToken() && !inMemoryJWT.isExpired()) {
        options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
        return blob ? fetchBlob(url, options) : fetchUtils.fetchJson(url, options);
    } else {
        return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
            if (gotFreshToken) {
                options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
            }

             return blob ? fetchBlob(url, options) : fetchUtils.fetchJson(url, options);
        });
    }
};

const fetchBlob = (url, options = {}) => {
    return fetch(url, { ...options })
        .then(response =>
            response.blob().then(blob => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: blob,
            }))
        )
        .then(({ status, statusText, headers, body }) => {
            if (status < 200 || status >= 300) {
                return false;
            }

            return Promise.resolve({status, headers, body});
        });
};

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        if(data.hasOwnProperty('rawFile')) {
            buildFormData(formData, data['rawFile'], parentKey)
        } else {
            Object.keys(data).forEach(key => {
                buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        }
    } else if (data && data instanceof Date) {
        data.setTime(data.getTime() - data.getTimezoneOffset()*60*1000);
        formData.append(parentKey, data.toISOString());
    } else {
        formData.append(parentKey, data == null ? '' : data);
    }
}

const init =  {
    getList: (resource, params) => {
        const query = {
            sort: {
                column: params.sort.field,
                direction: params.sort.order
            },
            direction: params.sort.order,
            size: params.pagination.perPage,
            page: params.pagination.page,
            filter: params.filter,
            //roles: "ROLE_ADMIN"
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data.items,
            total: json.data.pagination.total,
        }));
    },

    getOneWithParams: (resource, params) => {

        const url = `${apiUrl}/${resource}/${params.id}/?${stringify(params)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data
        }));
    },

    getOne: (resource, params) => {


        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json.data
        }));
    },

    getMany: (resource, params) => {
        const query = {filter: {id: params.ids}};
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ json }) => ({ data: json.data.items }));
    },

    getManyReference: (resource, params) => {
        const query = {
            sort: {
                column: params.sort.field,
                direction: params.sort.order
            },
            size: params.pagination.perPage,
            page: params.pagination.page,
            filter: {
                ...params.filter,
                [params.target]: params.id
            },
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data.items,
            total: json.data.pagination.total,
        }));
    },

    update: (resource, params) => {
        let form = new FormData();
        buildFormData(form, params.data);

        return httpClient(`${apiUrl}/${resource}/${params.id}/edit`, {
            method: 'POST',
            body: form,
        })
        .then(({ json }) => ({
            data: { ...params.previousData, ...params.data, ...json.data} //hack, response must have updated `data` object
        }))
        .catch(error => {
            return Promise.reject(error); // rethrow it
        });
    },

    updateMany: (resource, params) => {
        const query = {filter: JSON.stringify({id: params.ids})};

        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        })
        .then(({ json }) => ({
            data: { ...params.data, ...json.data}
        }));

    },

    create: (resource, params) => {
        let form = new FormData();
        buildFormData(form, params.data);

        return  httpClient(`${apiUrl}/${resource}/create`, {
            method: 'POST',
            body: form,
        }).then(response => {
            return response;
        }).then(({ json }) => ({
            data: { id: null, ...params.data, ...json.data }, //hack, response must have `id` parameter
        })).catch(error => {
            return Promise.reject(error); // rethrow it
        });
    },
    delete: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({json}) => ({data: json})).catch(error => {
            if (!error.body.status) {
                error.message = error.body.errors.message;
                error.status = error.body.errors.code;
            }

            return Promise.reject(error); // rethrow it
        });
    },
    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };

        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
    uploadFile: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {}, true)
            .then(response => {
                if (!response) {
                    return {data: 'fail'};
                }

                let filename = null;
                const disposition = response.headers.get('content-disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches !== null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }

                const url = window.URL.createObjectURL(response.body);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);

                return {data: 'ok'};
            });
    }
};

export default init;
