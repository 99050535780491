import React, { useEffect, useRef, useState } from "react";
import { useRecordContext } from "react-admin";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslate } from "react-admin";
import { fileService } from "api/file-service";
import { makePhotoSource } from "utils/constants";
import { useSnackbar } from "notistack";

const PhotoButton = () => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  const t = useTranslate();
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [file, setFile] = useState(null);
  const [isFileDeleted, setIsFileDeleted] = useState(false);

  const hiddenFileInput = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = (_, reason) => {
    if (reason === "backdropClick") return;
    setOpen(false);
  };

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleDeleteFile = async () => {
    setFile(null);
    setIsFileDeleted(true);
  };

  const handleChangeFile = async (event) => {
    try {
      if (event.target.files) {
        setIsLoadingFile(true);
        const response = await fileService.uploadFile({
          file: event.target.files[0],
          folder: "admins",
          type: "photo",
        });
        setIsFileDeleted(false);
        setFile(response);

        setIsLoadingFile(false);
      }
    } catch (error) {
      if (error) {
        console.log(error);
        handleClickVariant(error.message, "error");
        setIsLoadingFile(false);
      }
    }

    event.target.value = "";
  };

  const handleSave = async () => {
    try {
      if (!file && record.photo) {
        setIsLoadingFile(true);
        await fileService.deleteFile(record.photo.id);
        setIsLoadingFile(false);
      }
      if (file) {
        await fileService.uploadPhoto(record.id, file.id);
      }
      handleClose();
    } catch (error) {
      setIsLoadingFile(false);
      handleClickVariant(error.message, "error");
    }
  };
  useEffect(() => {
    if (record?.photo) {
      setFile(record.photo);
    }
  }, [record]);

  if (!record) return null;

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Photo
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth disable>
        <DialogTitle>
          {t("resources.photoModal.title", "Photo Details")}
          <IconButton
            aria-label={t("resources.photos.modal.close", "Close")}
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="flex gap-4 mb-4">
            {isLoadingFile ? (
              <div>Loading...</div>
            ) : (
              (file || record.photo) &&
              !isFileDeleted && (
                <img
                  src={makePhotoSource(file?.source || record.photo?.source)}
                  alt="avatar"
                  style={{ width: "250px" }}
                />
              )
            )}

            {file && (
              <>
                <Button
                  onClick={handleClick}
                  variant="text"
                  size="icon"
                  className="text-black-primary"
                >
                  {t("resources.photoModal.editButton")}
                </Button>
                <Button
                  className="text-error"
                  onClick={handleDeleteFile}
                  variant="text"
                  size="icon"
                >
                  {t("resources.photoModal.removeButton")}
                </Button>
              </>
            )}
            {!file && (
              <Button
                variant="text"
                size="icon"
                className="text-black-primary"
                onClick={handleClick}
              >
                {t("resources.chat.sendFile")}
              </Button>
            )}
            <input
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              onChange={(event) => handleChangeFile(event)}
              ref={hiddenFileInput}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            {t("resources.photoModal.saveButton")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PhotoButton;
