import {
  ReferenceManyField,
  Datagrid,
  useRecordContext,
  Pagination,
  ReferenceField,
  TextField,
  DateTimeInput,
} from "react-admin";
import {
  DateFieldMod,
  TextFieldMod,
} from "../../../../../Component/Layouts/Field";
import StatusField from "../../../../../Component/Layouts/Field/StatusField";
import { AppointmentStatus } from "../../../Constant/AppointmentStatus";
import CrudMod from "../../../../../Component/Layouts/Button/CrudMod";
import { NoResults } from "Component/NoResults/NoResults";

const AppointmentsTab = () => {
  const record = useRecordContext();

  return (
    <ReferenceManyField
      reference={"doctor/appointment"}
      target="doctor"
      label={false}
      pagination={<Pagination />}
      deletable={false}
    >
      <Datagrid bulkActionButtons={false} empty={<NoResults />}>
        <TextFieldMod
          label="resources.appointments.fields.id"
          source="id"
          sortBy="id"
        />
        <ReferenceField
          label="resources.appointments.fields.client"
          source="clientId"
          reference="users"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <StatusField
          label="resources.appointments.fields.status"
          statusList={AppointmentStatus}
          sortable={true}
        />
        <DateFieldMod
          label="resources.appointments.fields.date"
          source="period.start"
          options={{
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }}
        />
        <DateFieldMod
          label="resources.appointments.fields.dateStart"
          source="period.start"
          options={{
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h24",
          }}
          showTime
        />
        <DateFieldMod
          label="resources.appointments.fields.dateEnd"
          source="period.end"
          options={{
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h24",
          }}
          showTime
        />
        <DateFieldMod
          label="resources.appointments.fields.createdAt"
          source="createdAt"
          options={{ hourCycle: "h24" }}
          showTime
        />
        <DateFieldMod
          label="resources.appointments.fields.updatedAt"
          source="updatedAt"
          options={{ hourCycle: "h24" }}
          showTime
        />
        <CrudMod edit="true" />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default AppointmentsTab;
