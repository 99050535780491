import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { chatService } from "api/chat-service";
import { Button, useTranslate } from "react-admin";
import { makePhotoSource } from "utils/constants";
import { fileService } from "api/file-service";
import { useSnackbar } from "notistack";

const docTypes = ["pdf", "docx"];

export const ChatPage = () => {
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [user, setUser] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const t = useTranslate();
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const hiddenFileInput = useRef(null);
  const messagesEndRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollTo({
          top: messagesEndRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 300);
  };

  const handleGetUser = useCallback(async () => {
    try {
      const response = await chatService.getUser(id);
      setUser(response);
    } catch (error) {
      console.error("Error fetching user:", error);
      handleClickVariant(error.message, "error");
    }
  }, [id]);

  const handleGetChatMessages = useCallback(async () => {
    if (!user?.chat) return;
    try {
      const response = await chatService.getChatMessages(user.chat);
      setMessages(response.items.reverse());
      setIsInitialLoad(false);
    } catch (error) {
      console.error("Error fetching messages:", error);
      handleClickVariant(error.message, "error");
    }
  }, [user?.chat]);

  const handleSendMessage = async () => {
    if (file) {
      try {
        await chatService.sendMessage(user.chat, {
          message: file.source,
          messageType: file.type,
        });
        await handleGetChatMessages();
        setFile(null);
        setFileName("");
        scrollToBottom();
      } catch (error) {
        console.error("Error sending message:", error);
        handleClickVariant(error.message, "error");
      }
    }
    if (!newMessage.trim() || !user?.chat) return;
    try {
      await chatService.sendMessage(user.chat, {
        message: newMessage,
        messageType: "text",
      });
      setNewMessage("");
      await handleGetChatMessages();
      scrollToBottom();
    } catch (error) {
      console.error("Error sending message:", error);
      handleClickVariant(error.message, "error");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Запобігаємо додаванню нового рядка
      handleSendMessage();
    }
  };

  const getFileType = (fileType) => {
    if (fileType.startsWith("image/")) {
      return "photo";
    } else if (fileType.startsWith("video/")) {
      return "video";
    } else if (
      fileType === "application/pdf" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return "document";
    } else {
      handleClickVariant("No support this format", "error");
      return null;
    }
  };

  const handleChangeFile = async (event) => {
    try {
      const fileType = getFileType(event.target.files[0].type);
      if (event.target.files && fileType) {
        setIsLoadingFile(true);
        const response = await fileService.uploadFile({
          file: event.target.files[0],
          folder: "admins",
          type: fileType,
        });
        setFile(response);

        setIsLoadingFile(false);
      }
    } catch (error) {
      if (error) {
        console.log(error);
        setIsLoadingFile(false);
        handleClickVariant(error.message, "error");
      }
    }

    event.target.value = "";
  };

  const renderFile = (fileType) => {
    switch (fileType) {
      case "photo": {
        return (
          <img
            src={makePhotoSource(file.source)}
            alt="avatar"
            style={{ width: "60px", height: "60px" }}
          />
        );
      }
      case "document": {
        return <div>{fileName}</div>;
      }

      case "video": {
        return <div>{fileName}</div>;
      }
    }
  };

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  useEffect(() => {
    handleGetUser();
  }, [handleGetUser]);

  useEffect(() => {
    scrollToBottom();
  }, [isInitialLoad]);

  useEffect(() => {
    if (!user) return;

    if (messages.length === 0) {
      handleGetChatMessages();
    }

    const interval = setInterval(() => {
      handleGetChatMessages();
    }, 3000);

    return () => clearInterval(interval);
  }, [user?.chat, user, handleGetChatMessages]);

  return (
    <div>
      <h1>
        {t("resources.chat.title")} {user?.name}
      </h1>

      <div
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          height: "calc(100vh - 300px)",
          overflowY: "scroll",
        }}
        ref={messagesEndRef}
      >
        {messages.length > 0 ? (
          messages.map((msg, index) => {
            return (
              <>
                {msg.type === "text" && (
                  <div
                    key={index}
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        backgroundColor:
                          msg.sender === "doctor" ? "#d0e7ff" : "#f1f1f1",
                        padding: "8px",
                        borderRadius: "8px",
                        maxWidth: "70%",
                      }}
                    >
                      <strong>
                        {msg.sender === "doctor"
                          ? `${user.doctor.firstName} ${user.doctor.lastName}`
                          : user.name}
                      </strong>
                      : {msg.text}
                    </div>
                  </div>
                )}
                {msg.type === "photo" && (
                  <div
                    key={index}
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        backgroundColor:
                          msg.sender === "doctor" ? "#d0e7ff" : "#f1f1f1",
                        padding: "8px",
                        borderRadius: "8px",
                        maxWidth: "70%",
                      }}
                    >
                      <strong>
                        {msg.sender === "doctor"
                          ? `${user.doctor.firstName} ${user.doctor.lastName}`
                          : user.name}
                      </strong>
                      :{" "}
                      <img
                        style={{ maxWidth: "300px" }}
                        src={makePhotoSource(msg.text)}
                      />
                    </div>
                  </div>
                )}
                {msg.type === "document" && (
                  <div
                    key={index}
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        backgroundColor:
                          msg.sender === "doctor" ? "#d0e7ff" : "#f1f1f1",
                        padding: "8px",
                        borderRadius: "8px",
                        maxWidth: "70%",
                      }}
                    >
                      <strong>
                        {msg.sender === "doctor"
                          ? `${user.doctor.firstName} ${user.doctor.lastName}`
                          : user.name}
                      </strong>
                      :{" "}
                      <a
                        href={makePhotoSource(msg.text)}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/file-view.png"}
                          alt="Play Icon"
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        Download File
                      </a>
                    </div>
                  </div>
                )}
                {msg.type === "video" && (
                  <div
                    key={index}
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        backgroundColor:
                          msg.sender === "doctor" ? "#d0e7ff" : "#f1f1f1",
                        padding: "8px",
                        borderRadius: "8px",
                        maxWidth: "70%",
                      }}
                    >
                      <strong>
                        {msg.sender === "doctor"
                          ? `${user.doctor.firstName} ${user.doctor.lastName}`
                          : user.name}
                      </strong>
                      :{" "}
                      <a
                        href={makePhotoSource(msg.text)}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/play-video.png"}
                          alt="Play Icon"
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        Open Video
                      </a>
                    </div>
                  </div>
                )}
              </>
            );
          })
        ) : (
          <p>{t("resources.chat.noMessages")}</p>
        )}
      </div>

      <div style={{ marginTop: "10px", display: "flex", alignItems: "end" }}>
        <textarea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={t("resources.chat.inputPlaceholder")}
          style={{
            width: "70%",
            padding: "10px",
            marginRight: "5px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            height: "60px",
            resize: "none",
          }}
        />
        <div>
          <div className="flex gap-4 mb-4">
            {isLoadingFile ? (
              <div>Loading...</div>
            ) : (
              file && renderFile(file.type)
            )}

            {file && (
              <>
                <Button
                  onClick={handleClick}
                  variant="text"
                  size="icon"
                  className="text-black-primary"
                >
                  Edit
                </Button>
                <Button
                  className="text-error"
                  onClick={handleDeleteFile}
                  variant="text"
                  size="icon"
                >
                  Remove
                </Button>
              </>
            )}
            {!file && (
              <Button
                variant="text"
                size="icon"
                className="text-black-primary"
                onClick={handleClick}
              >
                {t("resources.chat.sendFile")}
              </Button>
            )}
            <input
              accept="image/*, .pdf, .docx, video/*"
              type="file"
              style={{ display: "none" }}
              onChange={(event) => handleChangeFile(event)}
              ref={hiddenFileInput}
            />
          </div>
          <button
            onClick={handleSendMessage}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            {t("resources.chat.sendMessage")}
          </button>
        </div>
      </div>
    </div>
  );
};
