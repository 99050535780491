import { useRecordContext} from 'react-admin';
import Chip from "@material-ui/core/Chip";
import {LabelValueFromConst} from "Component/Handlers/ValueFromConst";
import React from "react";

const StatusField = props => {
    const record = useRecordContext();
    const defaultColors = {active: '#32A232', blocked: '#d32f2f', paid: '#32A232', registered: '#ed6c02', pending: '#ed6c02', confirmed: '#32A232'};
    const handlColor = props.color || {};
    const handlStyle = props.style || {};

    return (
        <Chip
            style={{...handlStyle,...{color: 'white', background: {...defaultColors, ...handlColor}[record.status]}}}
            label={LabelValueFromConst(props.statusList, record.status, '-')}
        />
    );
};

export default StatusField;

