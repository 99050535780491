import englishMessages from 'ra-language-english';
import ukrainianMessages from 'ra-language-ukrainian';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {en} from "../../utils/i18n/en";
import {ua} from "../../utils/i18n/ua";

const translations = { "en": en, "ua": ua };
const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    'en',
    [{ locale: 'en', name: 'English' }, { locale: 'ua', name: 'Українська' }]
);

export default i18nProvider
